import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { Article } from "../../components"

const InsuranceExplained = (): JSX.Element => (
  <Article
    title="Insurance explained..."
    image={
      <StaticImage
        src={`../../assets/images/insurance-basics/article-1.png`}
        alt=""
        placeholder="blurred"
        quality={100}
      />
    }
    isSubComponent={false}
  >
    <p>
      Life is unpredictable, as an individual with dependents the most natural
      thing you worry about is how do I make sure my loved ones are taken care
      of when I am no longer around.
    </p>
    <p>
      Workerslife Insurance and Assurance Limited helps you prepare and plan for
      the uncertain future we all worry about; our mission is to make it easier
      for you to put measures in place to ensure that you and your loved ones
      get dignified burials and make sure your family’s financial future is
      secure through generational wealth transfer.
    </p>
    <p>
      Workerslife Insurance and Assurance Limited is dedicated to provide
      innovative affordable products and solutions that cater to the African
      family, we are Africans and our products are inspired by Africans. Our
      mission is to make sure that every South African, regardless of their race
      and social status, has an opportunity to cover and protect their family’s
      financial wellbeing.
    </p>
  </Article>
)

export default InsuranceExplained
