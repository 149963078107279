import React from "react"
import styled from "styled-components"

import { SEO } from "../components"
import {
  Articles,
  FAQs,
  InsuranceExplained,
} from "../sections/insurance-basics"

const InsuranceBasics: React.FC = () => {
  return (
    <PageWrapper>
      <SEO
        title="Insurance Basics"
        description="Understand insurance basics with Workerslife. Learn how to secure your family's future with funeral cover, income protection, and legal assistance"
      />
      <InsuranceExplained />
      <Articles />
      <FAQs />
    </PageWrapper>
  )
}

export default InsuranceBasics

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  .sectionHeader {
    margin: 60px auto 40px auto;
    max-width: 970px;

    h2 {
      text-align: center;
      margin-bottom: 10px;
    }

    p {
      font-style: italic;
      font-weight: 300;
      text-align: center;
      margin-bottom: 40px;
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
      margin: 40px auto 90px auto;

      p {
        font-size: 26px;
        margin-bottom: 30px;
      }
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.md}) {
      margin: 100px auto 40px auto;
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.lg}) {
      margin: 0 auto 90px auto;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 20px;
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    gap: 110px;
    margin: 0 auto;
    padding: 0 58px;
  }
`
