import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import { ProductArticle } from "../../components"

import { PRODUCT_COLOR } from "."

const PATH = "../../assets/images/insurance-basics/"

const Articles: React.FC = () => {
  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  const ArticleProperties = {
    color: PRODUCT_COLOR,
    highlightTitle: false,
    backArrow: false,
    className: "articleContainer",
  }

  return (
    <div>
      <div className="sectionHeader">
        <h2>
          We want to invest in you, your well being and the future of your
          family.
        </h2>
        <p>We help you build a portfolio that caters to your needs.</p>
      </div>
      <ArticlesContainer>
        <ProductArticle
          {...ArticleProperties}
          title="When something happens to you, what happens in the short term?"
          image={
            <StaticImage src={`${PATH}/article-2.png`} {...ImageProperties} />
          }
          imageLeft
        >
          <p>
            We have funeral cover/s that can be tailored according to your needs
            and size of your family.
          </p>
          <p>
            Paying for funerals often means using savings or taking out loans.
            Our funeral plans settle all funeral costs, therefore giving our
            clients and their loved ones a dignified funeral/farwell.
          </p>
        </ProductArticle>
        <ProductArticle
          {...ArticleProperties}
          title="What happens in the medium to long term? What happens after the funeral?"
          image={
            <StaticImage src={`${PATH}/article-3.png`} {...ImageProperties} />
          }
        >
          <p>
            Workerslife Life and Live Secure benefit will make sure that your
            family does not experience the loss of your income by replacing 80%
            of your income, for a period of up to 10 years (minimum of 5 years).
          </p>
        </ProductArticle>
        <ProductArticle
          {...ArticleProperties}
          title="What happens should you have a legal dispute or need legal advice?"
          image={
            <StaticImage src={`${PATH}/article-4.png`} {...ImageProperties} />
          }
          imageLeft
        >
          <p>
            Workerslife Insurance Limited has lawyers on call that can help you
            and your family when you require legal assistance for any legal
            matter which include criminal, civil or labour matters.
          </p>
        </ProductArticle>
      </ArticlesContainer>
    </div>
  )
}

export default Articles

const ArticlesContainer = styled.div`
  display: grid;

  .articleContainer {
    padding-right: 0;

    .article {
      gap: 80px;

      .imageContainer {
        height: 88%;
      }

      h1 {
        background-color: transparent;
        color: #000;
      }

      h1,
      p,
      .articleFooter {
        padding-left: 0;
        padding-right: 0;
      }

      @media screen and (max-width: ${props => props.theme.maxBreakpoints.md}) {
        h1 {
          margin: 0;
        }
      }
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      &:nth-child(even) .coverButton {
        justify-self: flex-start;
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    gap: 140px;

    &:nth-child(odd) .article {
      grid-template-columns: 1fr minmax(500px, 520px);
    }

    &:nth-child(even) .article {
      grid-template-columns: minmax(500px, 520px) 1fr;
    }

    .article .textContainer {
      h1 {
        margin-top: 0;
        max-width: 505px;
        padding-top: 0;
      }
    }
  }
`
