import React from "react"
import styled from "styled-components"

import { FAQsContent } from "../FAQs"

const FAQs: React.FC = () => {
  return (
    <Container>
      <div className="sectionHeader">
        <h2>Frequently Asked Questions</h2>
        <p>Everything you need to know about Workerslife.</p>
      </div>
      <FAQsContent />
    </Container>
  )
}

export default FAQs

const Container = styled.div`
  > div {
    padding: 0;
  }
`
